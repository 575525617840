import React from 'react'
import Main from '../../layouts/Main'
import Transition from '../../components/Transition'

const Page500 = () => {
  return (
    <Main>
      <Transition>
        <h1>500</h1>
      </Transition>
    </Main>
  )
}

export default Page500
